import styled, { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  padding: 24px;
  color: #fff;
  background-color: #111f37;
  animation-duration: 500ms;
  z-index: 9000;

  ${p =>
    p.$isShow &&
    css`
      animation-name: ${fadeIn};
    `}

  ${p =>
    p.$isHide &&
    css`
      animation-name: ${fadeOut};
    `}

  @media (max-width: 507px) {
    right: 16px;
    bottom: 16px;
    left: 16px;
  }

  @media (min-width: 508px) {
    right: 16px;
    bottom: 16px;
    width: 508px;
  }
`;

export const Title = styled.div`
  font-family: 'GreatWestern';
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const Desc = styled.div`
  max-width: 650px;
  margin-top: 24px;
  margin-bottom: 40px;

  a,
  button {
    color: #dc8d3c;
  }
`;

export const StyledLink = styled.button`
  text-decoration: underline;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 24px;
  font-family: 'DharmaGothic';
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  border-radius: 16px;

  &.--solid {
    color: #fff;
    background-color: #d1914c;
  }

  &.--outline {
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }
`;
