import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';

import {
  Root,
  Title,
  Desc,
  StyledLink,
  Actions,
  Button,
} from './CookieDisclaimer.style';

const CookieDisclaimer = () => {
  const cookieDisclaimer = Cookies.get('cookieDisclaimer');
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
  const [isRunAnimation, setIsRunAnimation] = useState(false);

  useEffect(() => {
    if (cookieDisclaimer !== 'dismissed') setIsShowDisclaimer(true);
  }, []);

  const onHideDisclaimer = () => {
    setIsRunAnimation(prevState => !prevState);

    setTimeout(() => {
      setIsShowDisclaimer(false);
    }, 500);
  };

  const handleHideDisclaimer = () => {
    Cookies.set('cookieDisclaimer', 'dismissed', {
      path: '/',
      secure: true,
      expires: 365,
      domain: process.env.GATSBY_FRONTEND_DOMAIN || 'localhost',
    });

    onHideDisclaimer();
  };

  return (
    isShowDisclaimer && (
      <Root $isShow={isShowDisclaimer} $isHide={isRunAnimation}>
        <Title>Cookie consent</Title>
        <Desc>
          We use cookies to operate our services. We also share information
          about your use of our site with our advertising and analytics partners
          in the United States. By clicking “Accept,” you agree to our use of
          cookies.{' '}
          <StyledLink as={Link} to="/cookie-policy">
            Cookie Policy
          </StyledLink>
          .
        </Desc>

        <Actions>
          <Button className="--outline" onClick={onHideDisclaimer}>
            Reject
          </Button>
          <Button className="--solid" onClick={handleHideDisclaimer}>
            Accept
          </Button>
        </Actions>
      </Root>
    )
  );
};

export default CookieDisclaimer;
